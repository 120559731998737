<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6"
        label="Nome"
        v-model="internalValue.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <SelectZoneWidget
        class="col-md-6"
        label="Zona"
        v-model="internalValue.zone"
        :errorMsg="errorHash.zone"
        @input="removeFieldError('zone')"
      />

      <FormInputTextareaWidget
        class="col-12"
        label="Descrizione"
        v-model="internalValue.description"
        :errorMsg="errorHash.description"
        @input="removeFieldError('description')"
      />
    </div>

    <fieldset class="row mt-5">
      <legend>Dati amministrativi</legend>

      <FormInputTextWidget
        label="Ragione sociale"
        v-model="internalValue.companyName"
        :errorMsg="errorHash.companyName"
        @input="removeFieldError('companyName')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Partita Iva"
        not-required
        v-model="internalValue.vatCode"
        :errorMsg="errorHash.vatCode"
        @input="removeFieldError('vatCode')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Codice fiscale"
        not-required
        v-model="internalValue.fiscalCode"
        :errorMsg="errorHash.fiscalCode"
        @input="removeFieldError('fiscalCode')"
      />

      <FormInputTextWidget
        class="col-12"
        label="Coordinate bancarie"
        not-required
        v-model="internalValue.bankAccount"
        :errorMsg="errorHash.bankAccount"
        @input="removeFieldError('bankAccount')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Indirizzo"
        v-model="internalValue.addressName"
        :errorMsg="errorHash.addressName"
        @input="removeFieldError('addressName')"
      />

      <FormInputTextWidget
        class="col-md-6"
        not-required
        label="Numero civico"
        v-model="internalValue.addressNumber"
        :errorMsg="errorHash.addressNumber"
        @input="removeFieldError('addressNumber')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Città"
        v-model="internalValue.city"
        :errorMsg="errorHash.city"
        @input="removeFieldError('city')"
      />

      <FormInputTextWidget
        class="col-md-3"
        label="Provincia"
        v-model="internalValue.province"
        :errorMsg="errorHash.province"
        @input="removeFieldError('province')"
      />

      <FormInputTextWidget
        class="col-md-3"
        label="Cap"
        v-model="internalValue.zipCode"
        :errorMsg="errorHash.zipCode"
        @input="removeFieldError('zipCode')"
      />

      <FormInputTextWidget
        class="col-md-6"
        not-required
        label="Numero di telefono"
        type="tel"
        v-model="internalValue.phoneNumber"
        :errorMsg="errorHash.phoneNumber"
        @input="removeFieldError('phoneNumber')"
      />

      <FormInputTextWidget
        class="col-md-6"
        not-required
        label="Indirizzo email"
        type="email"
        v-model="internalValue.emailAddress"
        :errorMsg="errorHash.emailAddress"
        @input="removeFieldError('emailAddress')"
      />

      <FormInputTextareaWidget
        class="col-md-12"
        not-required
        label="Note"
        v-model="internalValue.notes"
        :errorMsg="errorHash.notes"
        @input="removeFieldError('notes')"
      />
    </fieldset>

    <div class="row">
      <div class="col-12 mt-4 text-end">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputTextareaWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextareaWidget.vue';
import SelectZoneWidget from './Widgets/SelectZoneWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FormInputTextareaWidget,
    SelectZoneWidget,
    FeedbackAlert,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
        zoneId: null,
        description: null,

        companyName: null,
        vatCode: null,
        fiscalCode: null,
        bankAccount: null,
        addressName: null,
        addressNumber: null,
        city: null,
        province: null,
        zipCode: null,
        phoneNumber: null,
        emailAddress: null,
        notes: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      iValue: { ...this.value },
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.iValue.name) {
        this.addError('Inserisci il nome', 'name');
      }

      if (!this.iValue.name) {
        this.addError('Inserisci la descrizione per il sito web', 'description');
      }

      if (!this.iValue.companyName) {
        this.addError('Inserisci la ragione sociale', 'companyName');
      }

      if (!this.iValue.vatCode && !this.iValue.fiscalCode) {
        this.addError('Inserisci codice fiscale o partita iva', 'vatCode');
      }

      if (!this.iValue.addressName) {
        this.addError('Inserisci l\'indirizzo', 'addressName');
      }

      if (!this.iValue.city) {
        this.addError('Inserisci la città', 'city');
      }

      if (!this.iValue.province) {
        this.addError('Inserisci la provincia', 'province');
      }

      if (!this.iValue.zipCode) {
        this.addError('Inserisci il cap', 'zipCode');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const valueToReturn = { ...this.iValue };

      this.$emit('submitForm', valueToReturn);
    },
  },
};

</script>

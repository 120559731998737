<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="zoneOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue'),
  },
  props: {
    zones: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      allZones: null,
    };
  },
  computed: {
    completeZones () {
      return this.zones || this.allZones || [];
    },
    zoneOptions () {
      return this.completeZones.map(p => {
        p.value = p.id;
        p.label = p.name;

        return p;
      });
    },
  },
  mounted () {
    if (null === this.zones) {
      this.$api.listZones()
        .then(res => {
          this.allZones = res.data.zones;
        })
        .catch(this.$log.error)
      ;
    }
  },
};

</script>
